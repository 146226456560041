<script>
import { useStore } from 'vuex'
import { onMounted, reactive, toRefs } from 'vue'

const stepName = 'Treated_Or_Prescribed_Last_Five_Years'

const keys = [
  'Anxiety / depression / bipolar',
  'Cancer',
  'Chronic Pain',
  'Diabetes',
  'Heart of circulatory disorder',
  'Respiratory disorder',
  'Other medical condition',
  'I have no medical conditions'
]

const questionText = 'In the past 5 years have you been treated or prescribed medication for any of the following conditions?'

export default {
  name: stepName,

  emits: ['next-step'],

  setup(_, {emit}) {
    const store = useStore()

    const state = reactive({
      anxiety_depression_bipolar: false,
      cancer: false,
      chronic_pain: false,
      diabetes: false,
      heart_or_circulatory_disorder: false,
      respiratory_disorder: false,
      other_medical_conditions: false,
      have_no_medical_conditions: false
    })

    onMounted(() => {
      store.commit('SET_IS_LOADING', false)
    })

    const setAnswer = () => {
      //if user selects anxiety depression, show anxiety/depression question
      //if user selects other, show question asking if any of the listed conditions are visible question
      //if user selects anxiety AND other, other will take priority
      //if user selects NONE, have no medical conditions will be set to 1

      const condition_fields = [
        {field: 'anxiety_depression_bipolar', value: state.anxiety_depression_bipolar ? 1 : 0},
        {field: 'cancer', value: state.cancer ? 1 : 0},
        {field: 'chronic_pain', value: state.chronic_pain ? 1 : 0},
        {field: 'diabetes', value: state.diabetes ? 1 : 0},
        {field: 'heart_or_circulatory_disorder', value: state.heart_or_circulatory_disorder ? 1 : 0},
        {field: 'respiratory_disorder', value: state.respiratory_disorder ? 1 : 0},
        {field: 'other_medical_conditions', value: state.other_medical_conditions ? 1 : 0},
      ]

      //check if any of conditions fields are answered, it does not make sense to have no medical conditions and select a medical condition
      const anyConditionsFlagged = condition_fields.some(condition => condition.value === 1)
      const haveNoMedicalCondition = {field: 'have_no_medical_conditions', value: anyConditionsFlagged ? 0 : 1 }

      const answers = [
        ...condition_fields,
        haveNoMedicalCondition
      ]

      store.commit('SET_FIELDS', answers)
      emit('next-step', stepName)
    }

    return {
      ...toRefs(state),
      questionText,
      keys,
      setAnswer
    }
  }
}
</script>

<template>
  <div>
    <div class="row">
      <div class="box">
        <h2 class="main-heading button-answers-heading">{{ questionText }}</h2>
      </div>
    </div>

    <div class="row checkbox-container-row">
      <div class="box">
        <div class="checkbox-container">
          <div class="checkbox-row">
            <input class="checkbox-input" type="checkbox" v-model="anxiety_depression_bipolar">
            <label class="checkbox-label">{{keys[0]}}</label>
          </div>
          <div class="checkbox-row">
            <input class="checkbox-input" type="checkbox" v-model="cancer">
            <label class="checkbox-label">{{keys[1]}}</label>
          </div>
          <div class="checkbox-row">
            <input class="checkbox-input" type="checkbox" v-model="chronic_pain">
            <label class="checkbox-label">{{keys[2]}}</label>
          </div>
          <div class="checkbox-row">
            <input class="checkbox-input" type="checkbox" v-model="diabetes">
            <label class="checkbox-label">{{keys[3]}}</label>
          </div>
          <div class="checkbox-row">
            <input class="checkbox-input" type="checkbox" v-model="heart_or_circulatory_disorder">
            <label class="checkbox-label">{{keys[4]}}</label>
          </div>
          <div class="checkbox-row">
            <input class="checkbox-input" type="checkbox" v-model="respiratory_disorder">
            <label class="checkbox-label">{{keys[5]}}</label>
          </div>
          <div class="checkbox-row">
            <input class="checkbox-input" type="checkbox" v-model="other_medical_conditions">
            <label class="checkbox-label">{{keys[6]}}</label>
          </div>
          <div class="checkbox-row">
            <input class="checkbox-input" type="checkbox" v-model="have_no_medical_conditions">
            <label class="checkbox-label">{{keys[7]}}</label>
          </div>
        </div>

        <button
          class="main-button main-button-w mt-sm"
          @click="setAnswer"
        >
          Continue
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../../../assets/styles/component_styles/questionnaire/treated_or_prescribed_last_five_years';
</style>